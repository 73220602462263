@import 'assets/styles/_variables.module.scss';

.image-download-button {
    background: linear-gradient(88.81deg, #00a78e -19.12%, #2fc48d 111.08%);
    border-radius: 3px;
    height: 38px;
    border: none;
    color: #ffffff;
    text-align: center;
    width: 100%;
    cursor: pointer;
    border-bottom: 2px solid #00a78e;

    .container {
        position: relative;
        display: flex;

        .icon-container {
            position: absolute;
            left: 0;
            top: -3px;

        }
        .label {
            font-weight: 500;
            font-size: 12px;
            margin: auto;
            display: flex;
            align-items: center;
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .image-download-button {
        width: 50%;
        min-width: min(250px, calc(92vw));
    }
}

@media only screen and (min-width: $page-width-s) {
    .image-download-button {
        position: absolute;
        bottom: 0;
        width: calc(100% - 62px);
        margin: 0 8px;

        .container {
            justify-content: space-between;
        }
    }
}
