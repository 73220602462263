.white-background {
    &.color-selection {
        position: absolute;
        top: 0;
        padding-top: 59px;
        left: calc(50% - 240px/2);
        background-color: #ffffff;
        width: 240px;
        height: 429px;
        z-index: 99;
    }

    &.desktop-view {
        width: 635px;
        height: 451px;
        left: calc(50% - 635px/2);
    }
}

.color-selection-tutorial {
    position: absolute;
    background: #000000;
    opacity: 0.95;
    color: #ffffff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;

    .close-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px;
        background: transparent;
        cursor: pointer;
        border: none;
    }

    .dots-container {
        display: flex;
        justify-content: space-between;
        width: 33px;

        .dot {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.3);
            cursor: pointer;

            &.active {
                background: rgb(255, 255, 255);
            }
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        padding-top: 59px;

        &.desktop-size {
            div {
                width: 635px;
                height: 451px;
            }
        }

        div {
            background-size: cover;
            width: 240px;
            height: 429px;

            .tutorial-text {
                position: absolute;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                color: #000000;
                width: fit-content;
                height: fit-content;
    
                &.-desktop {
                    font-size: 14px;
                }
        
                &.text-page-1 {
                    left: 50%;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    top: 120px;
        
                    &.-desktop {
                        left: calc(50% - 170px);
                        top: 145px;
                    }
                }
        
                &.text-page-2 {
                    left: 50%;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    top: 75px;
        
                    &.-desktop {
                        left: 50%;
                        top: 75px;
                    }
                }
        
                &.text-page-3 {
                    left: 50%;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    top: 100px;
        
                    &.-desktop {
                        left: calc(50% - 80px);
                        top: 100px;
                    }
                }
    
                .p-r {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
    
                    .text-container {
                        border-radius: 6px;
                        background: #ffffff;
                        padding: 6px 8px;
                        width: fit-content;
                        height: fit-content;
                    }
    
                    .point {
                        position: absolute;
    
                        &.point-up {
                            top: -17px;
                            transform: translateX(-50%);
                            left: 50%;
                        }
    
                        &.point-down {
                            bottom: -17px;
                            transform: translateX(-50%);
                            left: 50%;
                        }
                    }
                }
            }
        }

        .swiper {
            cursor: pointer;
        }

        #color-selection-tutorial-levis-ambience-nl-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-nl-1.jpg);
        }
        #color-selection-tutorial-levis-ambience-nl-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-nl-2.jpg);
        }
        #color-selection-tutorial-levis-ambience-nl-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-nl-3.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-nl-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-nl-1.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-nl-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-nl-2.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-nl-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-nl-3.jpg);
        }

        #color-selection-tutorial-levis-ambience-fr-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-fr-1.jpg);
        }
        #color-selection-tutorial-levis-ambience-fr-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-fr-2.jpg);
        }
        #color-selection-tutorial-levis-ambience-fr-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-levis-ambience-fr-3.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-fr-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-fr-1.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-fr-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-fr-2.jpg);
        }
        #color-selection-tutorial-desktop-levis-ambience-fr-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-levis-ambience-fr-3.jpg);
        }

        #color-selection-tutorial-flexa-creations-nl-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-flexa-creations-nl-1.jpg);
        }
        #color-selection-tutorial-flexa-creations-nl-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-flexa-creations-nl-2.jpg);
        }
        #color-selection-tutorial-flexa-creations-nl-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-flexa-creations-nl-3.jpg);
        }
        #color-selection-tutorial-desktop-flexa-creations-nl-image-1 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-flexa-creations-nl-1.jpg);
        }
        #color-selection-tutorial-desktop-flexa-creations-nl-image-2 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-flexa-creations-nl-2.jpg);
        }
        #color-selection-tutorial-desktop-flexa-creations-nl-image-3 {
            background-image: url(../../../public/images/tutorials/color-selection-tutorial-desktop-flexa-creations-nl-3.jpg);
        }
    }

    .tutorial-description {
        margin: auto;
        width: 318px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding-top: 15px;

        &.desktop-description {
            font-size: 20px;
            line-height: 27px;
            width: 480px;
        }
    }

    .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 360px;
        margin: auto;
        padding: 20px 25px 0 25px;
        
        &.desktop-controls {
            max-width: 635px;

            .prev-button,
            .next-button {
                font-size: 18px;
            }
        }

        .prev-button,
        .next-button {
            width: 100px;
            font-family: 'Open Sans';
            font-style: normal;
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;

            &.inactive {
                opacity: 0.1;
                pointer-events: none;
            }
        }

        .next-button {
            text-align: end;
        }
    }
}