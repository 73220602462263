$page-width-l: 1900px;
$page-width-m: 1200px;
$page-width-s: 1000px;
$page-width-xs: 500px;
$page-width-xxs: 350px;
$page-width-xxxs: 280px;

$page-height-xs: 690px;

$non-selected-color: rgb(211, 211, 211);
$image-ratio: 1.5;

:export {
    pageWidthS: $page-width-s;
    imageRatio: $image-ratio;
}
