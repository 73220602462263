.search-component-container {
    display: flex;
    padding: 9px 25px;
    background: #F6F6F6;
    align-items: center;

    .search-label {
        margin-left: 15px;
        width: 100%;
        
        .search-form {
            input {
                border: none;
                padding: 10px;
                background: none;
                width: 100%;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }
}

.search-result-container {
    position: absolute;
    z-index: 50;
    top: 162px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;

    .search-result-list {
        padding: 16px 55px;
        background: #ffffff;

        .product-container {
            display: flex;
            margin: 12px 0;
            align-items: center;

            .product-color {
                width: 80px;
                height: 80px;
                border-radius: 0px 15px;
                margin-right: 16px;
                cursor: pointer;
            }

            .product {
                cursor: pointer;
            }
        }
    }
}