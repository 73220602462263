@import 'assets/styles/_variables.module.scss';

.color-palette-container {
    .color-palette-subtitle {
        color: #a3a3a3;
        text-align: center;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &.desktop {
        .colorizable-color-container {

            .palette-container {
                .colors-container {
                    .colorizable-color-spacing {
                        &:last-child {
                            width: 100%;

                            .color-name-container {
                                width: 80px;
                                height: 66px;
                                p {
                                    width: min-content;
                                }
                            }

                            .colorizable-color {
                                width: 90%;
                                display: flex;
                                align-items: flex-end;
                                border-radius: 15px 0;
                            }
                        }

                        &.four-columns,
                        &.three-columns-resized {
                            &:last-child {
                                .color-name-container {
                                    height: 56px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .colorizable-colors {
            max-width: calc(100vw - 30px);
        }
    }

    .color-palette-subtitle {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #a3a3a3;
        padding: 0 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .colorizable-colors {
        padding: 0 15px 15px;

        .swiper-slide {
            padding-top: 8px;
        }

        .colorizable-area-title-container {
            width: 90%;
            margin: auto;
            position: relative;
            border-top: 2px solid #e7e7e7;
            padding-bottom: 28px;

            .colorizable-area-title {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -18px;
                width: max-content;
                background: white;
                padding: 5px 38px;
                text-align: center;
                margin: auto;
                font-weight: 500;
            }
        }
    }

    .colorizable-color-container {
        position: relative;

        .arrow-left-color-palette-button, 
        .arrow-right-color-palette-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 90;
            cursor: pointer;
        }

        .arrow-left-color-palette-button {
            left: 15px;
        }

        .arrow-right-color-palette-button {
            right: 15px;
        }

        .swiper {
            &.color-palette-swiper {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .palette-container {
            .title-container {
                padding-bottom: 30px;
            }

            .colors-container {
                display: flex;
                flex-wrap: wrap;
            }

            .palette-background-bottom {
                // width: 100% - (33.33% - (80px + 10px + 10px))
                width: calc(66.66% + 100px);
                height: 80px;
                border-radius: 0px 15px;
                margin: 6px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .color-palette-subtitle {
            display: block;
        }

        .swiper {
            .swiper-slide {
                padding-top: 8px;
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .colorizer-app {
        .color-palette-container {
            .colorizable-color-container {
                .swiper {
                    .swiper-slide {
                        .colorizable-area-title-container {
                            width: 95%;
                        }
                    }
                }
            }
        }
    }
}
