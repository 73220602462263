@import 'assets/styles/_variables.module.scss';

.end-screen-button {
    background: white;
    border-radius: 8px 0 8px 0;
    height: 39px;
    border: 1px solid #091339;
    text-align: center;
    padding: 0 15px;
    cursor: pointer;
    margin-bottom: 10px;

    .container {
        display: flex;
        justify-content: center;
    }

    .label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #091339;

    }
}