@import 'assets/styles/_variables.module.scss';

.color-box {
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    min-height: 30px;
    min-width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    border-radius: 0px 15px;
    background-color: $non-selected-color;
    height: calc(18px + (100vh - 667px) / ((800 - 667) / 12));
    padding: 0 3px;
}

.color-name {
    margin: 0;
    color: #ffffff;
    font-size: 10px;
}

@media only screen and (min-width: $page-width-s) {
    .color-box {
        height: 45px;
    }
}
