@import 'assets/styles/_variables.module.scss';

.colors-container {
    margin: auto;

    .colorizable-color-spacing {
        width: 33%;

        &.four-columns {
            width: 25%;

            .colorizable-color {
                width: 56px;
                height: 56px;

                .color-name-container {
                    p {
                        font-size: 9px;
                    }
                }
            }

            &:last-child {
                .colorizable-color {
                    height: 56px;

                    .color-name-container {
                        height: 56px;
                    }
                }
            }
        }

        &.three-columns-resized {
            .colorizable-color {
                width: 56px;
                height: 56px;

                .color-name-container {
                    p {
                        font-size: 9px;
                    }
                }
            }

            &:last-child {
                .colorizable-color {
                    height: 56px;

                    .color-name-container {
                        height: 56px;
                    }
                }
            }
        }


        .colorizable-color {
            margin: 6px auto;
            width: 70px;
            height: 70px;
            border-radius: 0px 15px;
            
            .color-name-container {
                p {
                    text-transform: uppercase;
                    word-wrap: break-word;
                    font-size: 13px;
                    margin: 0;
                }
            }
        }

        &:last-child {
            width: 100%;

            .color-name-container {
                width: 80px;
                height: 66px;
                p {
                    width: min-content;
                }
            }

            .colorizable-color {
                width: 90%;
                display: flex;
                align-items: flex-end;
                border-radius: 15px 0;
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .colorizer-app {
        .color-palette-container {
            .colorizable-color-container {      
                .swiper {
                    .swiper-slide {
                        padding-top: 8px;

                        .colors-container {
                            margin: auto;

                            .colorizable-color-spacing {
                                width: 33%;

                                &.four-columns {
                                    width: 25%;

                                    &:last-child {
                                        .color-name-container {
                                            height: 56px;
                                        }
                                    }
                                }

                                &.three-columns-resized {
                                    &:last-child {
                                        .color-name-container {
                                            height: 56px;
                                        }
                                    }
                                }

                                .colorizable-color {
                                    margin: 6px auto;
                                }

                                &:last-child {
                                    width: 100%;

                                    .color-name-container {
                                        width: 80px;
                                        height: 66px;
                                        p {
                                            width: min-content;
                                        }
                                    }

                                    .colorizable-color {
                                        width: 90%;
                                        display: flex;
                                        align-items: flex-end;
                                        border-radius: 15px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-height: $page-height-xs) {
    .colorizer-app {
        .color-palette-container {
            .colorizable-color-container {      
                .swiper {
                    .swiper-slide {
                        .colors-container {
                            .colorizable-color-spacing {
                                .colorizable-color {
                                    margin: 3px auto;
                                }

                                &:last-child {
                                    .colorizable-color {
                                        height: 80px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $page-width-xxs) {
    .colorizer-app {
        .color-palette-container {
            .colorizable-color-container {      
                .swiper {
                    .swiper-slide {
                        .colors-container {
                            .colorizable-color-spacing {
                                .colorizable-color {
                                    height: 60px;
                                    width: 60px;
                                }

                                &.four-columns,
                                &.three-columns-resized {
                                    .colorizable-color {
                                        height: 46px;
                                        width: 46px;

                                        .color-name-container {
                                            p {
                                                font-size: 9px;
                                            }
                                        }
                                    }
                                    &:last-child {
                                        .colorizable-color {
                                            width: 90%;

                                            .color-name-container {
                                                height: 46px;
                                            }
                                        }
                                    }
                                }

                                &:last-child {
                                    .colorizable-color {
                                        .color-name-container {
                                            height: 56px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $page-width-xxxs) {
    .colorizer-app {
        .color-palette-container {
            .colorizable-color-container {      
                .swiper {
                    .swiper-slide {
                        .colors-container {
                            .colorizable-color-spacing {
                                .colorizable-color {
                                    height: 60px;
                                    width: auto;
                                }
                                &.four-columns
                                &.three-columns-resized {
                                    .colorizable-color {
                                        &:last-child {
                                            .colorizable-color {
                                                height: 60px;
                                            }
                                        }   
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}