.language-selector {
    background: #ffffff;
    color: #000000;
    inset: 0;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
