.navigation-panel-container {
    position: relative;
    text-align: center;
    padding: 15px 20px;
    border-bottom: 2px solid #012169;

    .back {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);

        .icon-container {
            cursor: pointer;
        }
    }

    .navigation-panel-title {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}