.end-screen-container {
    background: rgba(0, 0, 0, 0.829);
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;

    .popup-modal {
        position: absolute;
        top: calc(50% - 178px/2 + 1px);
        left: calc(50% - 317px/2 + 1px);
        background: white;
        text-align: center;
        margin: auto;
        width: 317px;
        height: 178px;
        border-radius: 25px 0 25px 0;

        .close-popup-modal {
            background: transparent;
            position: absolute;
            right: 0;
            border: none;
            padding: 13px;
            cursor: pointer;
        }

        .choose-title {
            margin: 31px 0 20px 0;
            font-family: 'Open Sans';
            font-style: normal;
            text-align: center;
            line-height: 20px;
            font-weight: 700;
            font-size: 16px;
            color: #091339;
        }
    }
}