@import 'assets/styles/_variables.module.scss';

.room-container {
    display: flex;
    width: calc(100% - 5px);

    margin-top: 10px;

    .colorizer-instance-container {
        width: 100%;
        padding: 0 5px;
        text-align: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 30px;

        &:nth-child(1) {
            padding-left: 0;
        }
    
        &:last-child {
            padding-right: 0;
        }

        .colorizer-image-container {
            height: auto;
            border: 2px solid transparent;
            canvas {
                position: initial !important;
                cursor: pointer;
                width: 100%;
                bottom: 0;
            }
        }

        &.active {
            .colorizer-image-container {
                border: 2px solid #00A78E;
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .room-container {
        display: none;
    }
}