@import 'assets/styles/_variables.module.scss';

.room-colorizer-page .col.left {
    .swiper {
        width: 100%;
        // height: calc(80vw * 0.9 / $image-ratio);
        margin: 0;
    }

    .colorizer-instance-container {
        select {
            display: none;
        }
    }
}

/*styles for colorizer room in fullscreen */
@media only screen and (max-width: $page-width-m) {
    .akzo-container.mobile-landscape .room-colorizer-page {
        padding: 0;

        .col.left .swiper {
            height: 100vh;
            width: 100%;
            margin: auto;

            .swiper-slide {
                .colorizer-image-container {
                    canvas {
                        height: calc(576 / 768 * 100vw);
                        left: 0;
                        margin-left: max(
                            10px,
                            calc((100vw - (768 / 576 * 100vh)) / 2 + 10px)
                        );
                        margin-top: max(
                            0px,
                            calc((100vh - (576 / 768 * 100vw)) / 2)
                        );
                        max-height: calc(100vh);
                        max-width: calc(768 / 576 * 100vh - 20px);
                        width: calc(100vw - 20px);
                    }
                }

                &.swiper-slide-prev,
                &.swiper-slide-next {
                    .colorizer-image-container {
                        canvas {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .room-colorizer-page .left .swiper {
        height: calc(80vw / $image-ratio);

        .swiper-slide .colorizer-image-container canvas {
            width: 80%;
            margin-left: 10px;
            margin-right: 10px;
        }

        .swiper-slide-active {
            .colorizer-image-container {
                canvas {
                    left: 8%;
                }
            }
        }

        .swiper-slide-prev {
            .colorizer-image-container {
                canvas {
                    left: auto;
                    right: -7%;
                }
            }
        }

        .swiper-slide-next {
            .colorizer-image-container {
                canvas {
                    left: -7%;
                    transform: none;
                }
            }
        }
    }
}

@media only screen and (min-width: $page-width-s) {
    .room-colorizer-page .desktop {
        flex-direction: row;
        justify-content: center;

        .swiper {
            height: auto;

            .swiper-wrapper {
                aspect-ratio: $image-ratio;
            }
        }

        .swiper-slide-active,
        .swiper-slide-prev,
        .swiper-slide-next {
            .colorizer-image-container {
                canvas {
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
        }

        .swiper-slide-active {
            .colorizer-image-container {
                canvas {
                    margin: 0;
                }
            }
        }
    }
}
