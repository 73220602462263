@import 'assets/styles/_variables.module.scss';

.colorizable-colors {
    &.hidden {
        display: none;
    }

    .colorizable-color-container {
        display: flex;
        margin: 20px 0 0;

        flex-wrap: wrap;

        .colorizable-color {
            position: relative;
            cursor: pointer;
            font-size: 10px;
            min-height: 30px;
            min-width: 12px;
            padding: 10px;
            margin: 6px auto;
        }

        .color-name {
            position: absolute;
            font-size: 11px;
            left: 5px;
            bottom: 5px;
            margin: 0;
            color: white;
        }
    }
}

.colorizer-image-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    canvas {
        width: 80%;
        left: 0;
        transition-property: left, right, width;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

@media only screen and (max-width: $page-width-s) {
    .colorizable-colors {
        .colorizable-color-container {
            .colorizable-color {
                border-radius: 0px 15px;
            }
        }
    }
}

@media only screen and (max-width: $page-width-m) {
    #full-screen-room {
        display: block;

        canvas {
            width: auto;
            height: auto;
            max-width: 100vw;
            max-height: 100vh;
            padding: 0;
            margin: auto;
            display: block;
        }
    }
}
