.room-mobile {
    .white-background {
        &.room-selection {
            position: absolute;
            top: 0;
            padding-top: 61px;
            left: calc(50% - 240px / 2);
            background-color: #ffffff;
            width: 240px;
            height: 429px;
            z-index: 99;
        }

        &.landscape-tutorial {
            padding-top: 61px;
            left: calc(50% - 124px / 2);
            width: 134px;
            height: 240px;
        }
    }

    .landscape-bottom-background {
        position: absolute;
        top: 0;
        margin-top: 406px;
        left: calc(50% - 240px / 2);
        background-color: #000000;
        width: 240px;
        height: 136px;
        z-index: 99;
    }

    .room-selection-mobile-tutorial {
        position: absolute;
        background: #000000;
        opacity: 0.95;
        color: #ffffff;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 99;

        .close-button {
            position: absolute;
            right: 0;
            top: 0;
            padding: 20px;
            background: transparent;
            cursor: pointer;
            border: none;
        }

        .dots-container {
            display: flex;
            justify-content: space-between;
            width: 72px;

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.3);
                cursor: pointer;

                &.active {
                    background: rgb(255, 255, 255);
                }
            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            padding-top: 61px;

            .swiper-slide {
                display: flex;
                justify-content: center;
                div {
                    background-size: cover;
                    width: 240px;
                    height: 429px;
                }

                .tutorial-text {
                    position: absolute;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    width: fit-content;
                    height: fit-content;
                    color: #000000;

                    &.text-page-1 {
                        left: calc(50% - 130px / 2);
                        top: 35px;
                    }
        
                    &.text-page-2 {
                        left: calc(50% - 200px / 2);
                        width: 200px;
                        text-align: center;
                        top: 250px;
                    }
         
                    &.text-page-3 {
                        left: 50%;
                        transform: translateX(-50%);
                        top: 270px;
                    }
        
                    &.text-page-4 {
                        left: calc(50% + 70px);
                        transform: translateX(-100%);
                        top: 346px;
                    }

                    &.text-page-5 {
                        left: calc(50% - 190px / 2);
                        transform: translateY(-100%);
                        top: 185px;
                        width: 190px;
                        text-align: center;
                    }
        
                    &.text-page-6 {
                        text-align: center;
                        left: calc(50% - 90px);
                        top: 267px;
                        width: 190px;
                    }

                    .p-r {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
        
                        .text-container {
                            border-radius: 6px;
                            background: #ffffff;
                            padding: 6px 8px;
                            width: fit-content;
                            height: fit-content;
                        }
        
                        .point {
                            position: absolute;
    
                            &.point-up {
                                top: -17px;
                                transform: translateX(-50%);
                                left: 50%;
                            }
    
                            &.point-down {
                                bottom: -17px;
                                transform: translateX(-50%);
                                left: 50%;
                            }
    
                            &.point-left {
                                left: -17px;
                                transform: translateY(-50%);
                                top: 50%;
                            }
    
                            &.point-right {
                                right: -17px;
                                transform: translateY(-50%);
                                top: 50%;
                            }
                        }
                    }
                }
            }

            #room-selection-tutorial-levis-ambience-nl-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-1.jpg);
            }
            #room-selection-tutorial-levis-ambience-nl-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-2.jpg);
            }
            #room-selection-tutorial-levis-ambience-nl-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-3.jpg);
            }
            #room-selection-tutorial-levis-ambience-nl-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-4.jpg);
            }
            #room-selection-tutorial-levis-ambience-nl-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-5.jpg);
            }
            #room-selection-tutorial-levis-ambience-nl-image-6 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-nl-6.jpg);
                width: 240px;
                height: 481px;
            }

            #room-selection-tutorial-levis-ambience-fr-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-1.jpg);
            }
            #room-selection-tutorial-levis-ambience-fr-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-2.jpg);
            }
            #room-selection-tutorial-levis-ambience-fr-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-3.jpg);
            }
            #room-selection-tutorial-levis-ambience-fr-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-4.jpg);
            }
            #room-selection-tutorial-levis-ambience-fr-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-5.jpg);
            }
            #room-selection-tutorial-levis-ambience-fr-image-6 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-levis-ambience-fr-6.jpg);
                width: 240px;
                height: 481px;
            }

            #room-selection-tutorial-flexa-creations-nl-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-1.jpg);
            }
            #room-selection-tutorial-flexa-creations-nl-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-2.jpg);
            }
            #room-selection-tutorial-flexa-creations-nl-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-3.jpg);
            }
            #room-selection-tutorial-flexa-creations-nl-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-4.jpg);
            }
            #room-selection-tutorial-flexa-creations-nl-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-5.jpg);
            }
            #room-selection-tutorial-flexa-creations-nl-image-6 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-flexa-creations-nl-6.jpg);
                width: 240px;
                height: 481px;
            }

            .swiper {
                cursor: pointer;
            }
        }

        .tutorial-description {
            margin: auto;
            width: 318px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            padding-top: 30px;
        }

        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 360px;
            margin: auto;
            padding: 55px 25px 0 25px;

            .prev-button,
            .next-button {
                width: 100px;
                font-family: 'Open Sans';
                font-style: normal;
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;

                &.inactive {
                    opacity: 0.1;
                    pointer-events: none;
                }
            }

            .next-button {
                text-align: end;
            }
        }
    }
}
