html {
    background: white;

    body {
        margin: 0;
        font-family: 'Open Sans';
        font-style: normal;
        background: white;
        height: 100vh;

        .akzo {
            position: relative;
        }

        .flex {
            display: flex;
            align-items: center;
        }

        .d-none {
            display: none !important;
        }

        .m-auto {
            margin: auto;
        }

        .mt-11 {
            margin-top: 11px;
        }

        .mt-15 {
            margin-top: 15px;
        }

        .mt-20 {
            margin-top: 20px;
        }

        .mb-15 {
            margin-bottom: 15px;
        }

        .w-20 {
            width: 20%;
        }

        .w-25 {
            width: 25%;
        }

        .w-33 {
            width: 33%;
        }

        .w-50 {
            width: 50%;
        }

        .grey {
            background: #d9d9d9 !important;
        }

        /* Fix for the colorizer canvases to not block user input */
        & > canvas {
            display: none;
        }
    }
}
