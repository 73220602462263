@import 'assets/styles/_variables.module.scss';

.room-colorizer-page {
    padding-top: min(20px, calc(10px + (100vh - 667px) / ((800 - 667) / 13.3)));

    .desktop {
        display: block;
    }

    #full-screen-room {
        display: none;
        background: #000000;
    }

    .color-palette-subtitle {
        display: none;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #a3a3a3;
        padding: 0 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .col {
        padding: 0 20px;

        &.left {
            width: 55vw;
        }

        &.right {
            width: 366px;

            .color-select-title {
                margin-top: 25px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #666666;
            }
        }
    }
}

/*styles for colorizer room in fullscreen */
@media only screen and (max-width: $page-width-m) {
    .akzo-container.mobile-landscape .room-colorizer-page {
        .col.left .color-palette-subtitle,
        .col.right {
            display: none;
        }
    }

    .room-colorizer-page {
        padding: 0;
        height: unset;

        .col.left {
            padding: 0;
            width: 100%;
        }

        #full-screen-room {
            display: block;

            canvas {
                width: auto;
                height: auto;
                max-width: 100vw;
                max-height: 100vh;
                padding: 0;
                margin: auto;
                display: block;
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .room-colorizer-page {
        display: block;
        padding-left: 0;
        padding-right: 0;

        .col {
            padding: 0;

            &.left,
            &.right {
                width: 100%;
                text-align: center;
            }

            &.left {
                .color-palette-subtitle {
                    display: block;
                    margin: min(
                            10px,
                            calc(10px + (100vh - 667px) / ((800 - 667) / 26.6))
                        )
                        0;
                }
            }

            &.right {
                margin-top: -2%;
                position: relative;
                z-index: 5;
                padding-left: 4%;
                padding-right: 4%;
                width: 92%;

                .color-select-title {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (min-width: $page-width-s) {
    .room-colorizer-page {
        .desktop {
            display: flex;
            margin: auto;

            .col {
                padding: 0 10px;

                &.left {
                    max-width: 55vw;
                }

                &.right {
                    position: relative;
                    height: 530px;

                    .color-select-title {
                        font-size: 20px;
                        margin-right: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $page-width-m) {
    .room-colorizer-page {
        margin: 0 auto;

        .desktop {
            .col {
                padding: 0 10px;

                &.left {
                    max-width: 800px;
                }
            }
        }
    }
}

@media only screen and (min-width: $page-width-l) {
    .room-colorizer-page {
        max-width: 1900px;

        .desktop {
            .col {
                &.left {
                    width: 55%;
                }
            }
        }
    }
}
