@import 'assets/styles/_variables.module.scss';

$header-size-desktop: 103px;
$header-size-mobile: 60px;

.header-container {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    background-color: #F5F5F5;
    padding: 10px;
    height: $header-size-mobile;
    max-height: $header-size-mobile;
    flex: 0 0 $header-size-mobile;
    box-sizing: border-box;

    &.desktop {
        height: $header-size-desktop;
        max-height: $header-size-desktop;
        flex: 0 0 $header-size-desktop;

        .icon-container {
            flex-basis: 121px;
        }

        .akzo-icon {
            background-size: 121px;
            width: 121px;
            height: 19px;
        }

        .logo-icon {
            flex-basis: 92px;
        }
    }

    .icon-container {
        background-repeat: no-repeat;
    }

    .logo-icon {
        flex-basis: 56px;
        width: auto;
        height: 100%;
        background-position: left;
        background-size: contain;
    }

    .akzo-icon {
        background-image: url(../../assets/images/header/akzo.png);
        flex-basis: 66px;
        background-size: 66px;
        width: 66px;
        height: 10px;
    }

    .header-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #000000;
    }
}

@media only screen and (max-width: $page-width-xs) {
    .header-container {
        padding: 2px 10px;

    }
}
