@import 'assets/styles/_variables.module.scss';

.color-scheme-container {
    .blank-room {
        border-top: 2px solid #e7e7e7;
        position: relative;
        text-align: center;
        margin-top: 40px;

        .blank-room-title {
            position: absolute;
            top: -18px;
            left: 47%;
            transform: translateX(-50%);
            background: white;
            padding: 5px 8px;
        }

        .color-box {
            color: white;
            font-size: 10px;
        }
    }

    .color-box-container {
        padding-right: 25px;
        position: relative;
        padding-bottom: 20px;

        .refresh-icon {
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translateY(-90%);
        }
    }
}

.tonal-container,
.neutral-container,
.designers-choice-container {
    border-top: 2px solid #e7e7e7;
    position: relative;

    .tonal-title,
    .neutral-title,
    .designers-choice-title {
        text-transform: capitalize;
        position: absolute;
        top: 0;
        left: 47%;
        transform: translateX(-50%) translateY(-50%);
        background: white;
        padding: 5px 8px;
        height: 20px;
    }

    .tonal-color,
    .neutral-color,
    .designers-choice-color {
        cursor: pointer;
        text-align: center;
        font-size: 10px;
        min-height: 30px;
        max-height: 50px;
        min-width: 12px;
        padding: 10px;
        margin: 8px;
        border-radius: 0px 15px;

        &.tonal-bg-1,
        &.neutral-bg-1,
        &.designers-choice-bg-1 {
            background-color: $non-selected-color;
        }

        &.tonal-bg-2,
        &.neutral-bg-2,
        &.designers-choice-bg-2 {
            background-color: $non-selected-color;
        }

        &.tonal-bg-3,
        &.neutral-bg-3,
        &.designers-choice-bg-3 {
            background-color: $non-selected-color;
        }
    }

    .refresh-icon {
        background-image: url(../../assets/images/refresh.png);
        width: 34px;
        height: 34px;
        cursor: pointer;
    }
}

@media only screen and (min-width: $page-width-s) {
    .color-scheme-container {
        .div-container {
            margin-bottom: 15px;
        }

        .color-box-container {
            padding-bottom: 12px;

            .refresh-icon {
                right: -1px;
                transform: translateY(-70%);
            }
        }
    }
}

@media only screen and (max-width: $page-width-s) {
    .desktop {
        .color-scheme-container {
            .color-box-container {
                padding-bottom: 25px;
            }
        }
    }

    .blank-room {
        margin-top: min(
            25px,
            calc(16px + (100vh - 667px) / ((800 - 667) / 10))
        );

        .blank-room-title {
            font-size: 12px;
            font-weight: 600;
        }

        .color-box {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;

            .color-name {
                font-size: 10px;
            }
        }
    }

    .tonal-container,
    .neutral-container,
    .designers-choice-container {
        .tonal-title,
        .neutral-title,
        .designers-choice-title {
            font-size: 12px;
            font-weight: 600;
        }
        .color-box {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            .color-name {
                font-size: 10px;
            }
        }
    }
}

@media only screen and (max-width: $page-width-xs) {
    .color-scheme-container {
        .blank-room {
            margin-top: 20px;
        }

        .color-box {
            padding: 5px;
            margin-left: 4px;
            margin-right: 4px;
        }

        .color-box-container {
            padding-bottom: 5px;
            .refresh-icon {
                right: -10px;
                top: 70%;
            }
        }
    }
}
