.room-desktop {
    .white-background {
        &.room-selection {
            position: absolute;
            top: 0;
            padding-top: 50px;
            left: calc(50% - 257px / 2);
            background-color: #ffffff;
            z-index: 99;
        }

        &.desktop-view {
            width: 635px;
            height: 451px;
            left: calc(50% - 635px / 2);
        }
    }

    .room-selection-desktop-tutorial {
        position: absolute;
        background: #000000;
        opacity: 0.95;
        color: #ffffff;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 99;

        .close-button {
            position: absolute;
            right: 0;
            top: 0;
            padding: 20px;
            background: transparent;
            cursor: pointer;
            border: none;
        }

        .dots-container {
            display: flex;
            justify-content: space-between;
            width: 72px;

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.3);
                cursor: pointer;

                &.active {
                    background: rgb(255, 255, 255);
                }
            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            padding-top: 50px;

            div {
                background-size: cover;
                width: 635px;
                height: 451px;

                .tutorial-text {
                    position: absolute;
                    font-size: 14px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    width: fit-content;
                    height: fit-content;
                    color: #000000;

                    &.text-page-1 {
                        left: calc(50% - 255px);
                        top: 55px;
                    }
        
                    &.text-page-2 {
                        white-space: nowrap;
                        left: calc(50% - 80px);
                        transform: translateX(-50%);
                        top: 300px;
                    }
        
                    &.text-page-3 {
                        white-space: nowrap;
                        left: calc(50% + 120px);
                        transform: translateX(-50%);
                        top: 130px;
                    }
        
                    &.text-page-4 {
                        white-space: nowrap;
                        left: calc(50% + 255px);
                        transform: translateX(-100%);
                        top: 320px;
                    }
        
                    &.text-page-5 {
                        left: calc(50% + 120px);
                        transform: translateX(-50%);
                        text-align: center;
                        top: 280px;
                        width: 290px;
                    }

                    .p-r {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
        
                        .text-container {
                            border-radius: 6px;
                            background: #ffffff;
                            padding: 6px 8px;
                            width: fit-content;
                            height: fit-content;
                        }
        
                        .point {
                            position: absolute;

                            &.point-down {
                                bottom: -17px;
                                transform: translateX(-50%);
                                left: 50%;
                            }

                            &.point-left {
                                left: -17px;
                                transform: translateY(-50%);
                                top: 50%;
                            }
                            
                            &.point-right {
                                right: -17px;
                                transform: translateY(-50%);
                                top: 50%;
                            }
                        }
                    }
                }
            }

            #room-selection-tutorial-desktop-levis-ambience-nl-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-nl-1.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-nl-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-nl-2.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-nl-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-nl-3.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-nl-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-nl-4.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-nl-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-nl-5.jpg);
            }

            #room-selection-tutorial-desktop-levis-ambience-fr-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-fr-1.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-fr-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-fr-2.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-fr-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-fr-3.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-fr-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-fr-4.jpg);
            }
            #room-selection-tutorial-desktop-levis-ambience-fr-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-levis-ambience-fr-5.jpg);
            }

            #room-selection-tutorial-desktop-flexa-creations-nl-image-1 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-flexa-creations-nl-1.jpg);
            }
            #room-selection-tutorial-desktop-flexa-creations-nl-image-2 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-flexa-creations-nl-2.jpg);
            }
            #room-selection-tutorial-desktop-flexa-creations-nl-image-3 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-flexa-creations-nl-3.jpg);
            }
            #room-selection-tutorial-desktop-flexa-creations-nl-image-4 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-flexa-creations-nl-4.jpg);
            }
            #room-selection-tutorial-desktop-flexa-creations-nl-image-5 {
                background-image: url(../../../../public/images/tutorials/room-selection-tutorial-desktop-flexa-creations-nl-5.jpg);
            }

            .swiper {
                cursor: pointer;
            }
        }

        .tutorial-description {
            margin: auto;
            width: 318px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            padding-top: 30px;
        }

        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            padding: 55px 25px 0 25px;

            &.desktop-controls {
                max-width: 635px;

                .prev-button,
                .next-button {
                    font-size: 18px;
                }
            }

            .prev-button,
            .next-button {
                width: 100px;
                font-family: 'Open Sans';
                font-style: normal;
                cursor: pointer;
                font-weight: 600;
                line-height: 19px;

                &.inactive {
                    opacity: 0.1;
                    pointer-events: none;
                }
            }

            .next-button {
                text-align: end;
            }
        }
    }
}
