@import 'assets/styles/_variables.module.scss';

.colorizer-app {
    padding-top: min(20px, calc(10px + (100vh - 667px) / ((800 - 667) / 13.3)));
}

.akzo-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/*styles for colorizer room in fullscreen */
@media only screen and (max-width: $page-width-m) {
    .akzo-container {
        background: white;

        &.mobile-landscape {
            height: 100vh;
            background: black;

            .header-container,
            .navigation-panel-container {
                display: none;
            }
        }
    }
}
